import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';

import { vars } from '../lib/theme';
import { Container } from '../lib/styles';

import FormLogin from './forms/FormLogin';

const PageLogin = () => (
  <Root>
    <Container flush maxWidth={vars.break.xs}>
      <FormWrap>
        <FormLogin />
        <FooterLinks>
          <Link href="/join">
            <a>Create a free account</a>
          </Link>
          <Link href="/reset-password">
            <a>Reset your password</a>
          </Link>
        </FooterLinks>
      </FormWrap>
    </Container>
  </Root>
);

export default PageLogin;

// Styles

const Root = styled('div')`
  padding: ${vars.spacing[4]} 0;
`;

const FormWrap = styled('div')`
  padding: ${vars.spacing[2]};
`;

const FooterLinks = styled('div')`
  font-size: 0.8em;
  margin-top: ${vars.spacing[3]};
  text-align: center;
  cursor: pointer;
  a {
    margin-top: ${vars.spacing[1]};
    display: block;
    color: ${vars.colors.grey.dark};
  }
`;
