import React from 'react';
import Router from 'next/router';

import LoadingSpinner from './LoadingSpinner';

const Redirect = ({ condition, target, fallback = null, children }) => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (condition) {
      process.browser && Router.push(target);
    }
    if (!condition) setLoading(false);
  }, [condition]);

  if (loading) return fallback || <LoadingSpinner></LoadingSpinner>;
  return children;
};
export default Redirect;
