import React from 'react';

import { withApollo } from '../lib/apollo';

import HeadMeta from '../components/HeadMeta';
import useUser from '../components/useUser';
import Layout from '../components/Layout';
import Redirect from '../components/Redirect';
import PageLogin from '../components/PageLogin';

const Login = () => {
  const user = useUser();

  return (
    <Layout>
      <HeadMeta title="Log In" />
      <Redirect condition={user} target="/members">
        <PageLogin />
      </Redirect>
    </Layout>
  );
};

export default withApollo(Login);
