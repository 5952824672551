import React from 'react';
import PropTypes from 'prop-types';
import Router from 'next/router';
import { Formik } from 'formik';
import * as Yup from 'yup';
import styled, { css } from 'styled-components';

import { vars } from '../../lib/theme';
import { setTokens, unsetTokens } from '../../lib/auth';

import useAuth from '../useAuth';
import Button from '../Button';
import InputText from './inputs/InputText';
// import DisplayFormikState from './DisplayFormikState';

const FormLogin = ({ initialValues, onSubmit }) => {
  const [loading, setLoading] = React.useState(false);
  const auth = useAuth();

  const handleSubmit = async ({ username, password }) => {
    setLoading(true);
    const user = await auth.logIn(username, password);
    if (!user) {
      setLoading(false);
      return;
    }
    // set cookies and reload
    await setTokens(user.data.login);
    Router.reload();
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(
        values,
        { setSubmitting, setErrors /* setValues and other goodies */ }
      ) => {
        handleSubmit(values);
        setSubmitting(false);
      }}
      render={({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        handleReset,
        setFieldValue,
        setFieldTouched,
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            <Title>Log In</Title>
            <InputText
              css={`
                margin-bottom: ${vars.spacing[2]};
              `}
              id="username"
              type="text"
              label="Email Address"
              error={touched.username && errors.username}
              value={values.username}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <InputText
              css={`
                margin-bottom: ${vars.spacing[3]};
              `}
              id="password"
              type="password"
              label="Password"
              error={touched.password && errors.password}
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <Button
              theme="success"
              type="submit"
              // disabled={!isEmpty(errors)}
              loading={loading ? 1 : 0}
              block
            >
              Log In
            </Button>
            {/* <DisplayFormikState
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            /> */}
          </form>
        </>
      )}
    />
  );
};

FormLogin.propTypes = {
  // history: PropTypes.object.isRequired,
  initialValues: PropTypes.object,
};

export default FormLogin;

// Schema

const schema = Yup.object().shape({
  username: Yup.string().required('Please enter a username'),
  password: Yup.string().required('Please enter a password'),
});

// Styles

const Title = styled('h3')`
  text-align: center;
  margin-top: ${vars.spacing[1]};
  margin-bottom: ${vars.spacing[3]};
`;
